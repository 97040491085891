import Vue from 'vue'

// axios
import axios from 'axios'

import { API_URL } from '@/config/apiConfig'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

const token = localStorage.getItem('admin-token')
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = token
}

export default axiosIns
